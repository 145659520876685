@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.timeline-blocks-container {
  $containerDefaultHeight: 40px;

  position: relative;
  flex: 1 0 auto;
  height: ($containerDefaultHeight * 2);
  background-image: var(--repeating-oblique-small-pattern);

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
    background-image: var(--repeating-oblique-pattern);
  }
}

// timeline.blocks template
.block {
  position: absolute;
  height: 100%;
  top: 0;
  width: 10px;
  transition: background-color 0.3s linear;

  &:focus {
    outline: none;
  }

  &.selected {
    z-index: 1;
    outline: 2px dashed var(--color-warning);
  }

  // --------------------------------------------------------------------------------------------------
  // Up
  // --------------------------------------------------------------------------------------------------
  &.up {
    background-color: var(--color-uptime);
  }
  &.up-accessible {
    background-color: var(--color-uptimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Unjustified
  // --------------------------------------------------------------------------------------------------
  &.down_unjustified,
  .down_unjustified {
    background-color: var(--color-unjustifiedDowntime);
  }
  &.down_unjustified-accessible,
  .down_unjustified-accessible {
    background-color: var(--color-unjustifiedDowntimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Unplanned
  // --------------------------------------------------------------------------------------------------
  &.down_unplanned,
  .down_unplanned {
    background-color: var(--color-justifiedDowntime);
  }
  &.down_unplanned-accessible,
  .down_unplanned-accessible {
    background-color: var(--color-justifiedDowntimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.down_planned,
  .down_planned {
    background-color: var(--color-plannedDowntime);
  }
  &.down_planned-accessible,
  .down_planned-accessible {
    background-color: var(--color-plannedDowntimeAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.out_of_production {
    background-color: var(--color-outOfProduction);
  }
  &.out_of_production-accessible {
    background-color: var(--color-outOfProductionAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.unknown {
    background-color: var(--color-unknown);
  }
  &.unknown-accessible {
    background-color: var(--color-unknownAccessible);
  }
  // --------------------------------------------------------------------------------------------------
  // Planned
  // --------------------------------------------------------------------------------------------------
  &.connection_issues {
    background-color: var(--color-disconnected);
  }
  &.down_mixed {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
  &.hidden {
    background-color: var(--color-timeline-graph-background);
  }
}

// shiftMarkers template
.shift-marker {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 10;
  left: 50%;
  background-color: currentColor;
  transition: 0.1s linear;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    left: -5px;
    top: 25%;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &.manually-created-marker {
    opacity: 0.6;
  }
}

.shift-marker:hover {
  width: 3px;
  &:before {
    margin-left: 1px;
    transition: 0.1s linear;
    transform: rotate(-45deg) scale(1.66);
  }
  &:after {
    margin-left: 1px;
    transition: 0.1s linear;
    transform: rotate(45deg) scale(1.66);
  }
}

.progress-marker {
  position: absolute;
  top: -6px;
  width: 3px;
  height: calc(100% + 17px);
  background-color: currentColor;
}

.v-tooltip__content {
  background-color: var(--color-inverted-container-theme);
}

.wx-info-window {
  max-width: 220px;
  color: var(--color-inverted-text-theme);
  background-color: var(--color-inverted-container-theme);

  .text {
    opacity: 0.9;
  }
}

.defaultMouseCursor {
  cursor: default;
}

.tooltip-right {
  background-color: var(--color-base-theme);
  color: var(--color-text-theme);
  opacity: 1;
}
.tooltip-right::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -8px;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px transparent;
  border-right: solid 8px var(--color-base-theme);
}
.tooltip-right.primary::before {
  border-right: solid 8px var(--color-base-theme) !important;
}

.tooltip-left {
  background-color: var(--color-base-theme);
  color: var(--color-text-theme);
  opacity: 1;
}
.tooltip-left::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%; /* To the left of the tooltip */
  margin-top: -8px;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px var(--color-base-theme);
  border-right: solid 8px transparent;
}
.tooltip-left.primary::before {
  border-left: solid 8px var(--color-base-theme) !important;
}
