@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.panel-downtime-justification {
  padding: var(--box-padding-dashboard);
  background-color: var(--color-base-background);
  box-shadow: none !important;

  .v-form {
    height: 100%; // required for `.scrollbar-y-container`
  }

  @media ($wx-sm-min) {
    .panel-header,
    .panel-body {
      display: flex;
    }
  }
}

.panel-header {
  align-items: center;
  margin-bottom: 5px;

  > :first-child {
    margin-right: auto;
  }
  .downtime-specs {
    font-weight: normal;
  }
  .arrows {
    margin-left: var(--btn-inline-margin);
  }

  // mobilePhone only
  @media ($wx-xs-max) {
    .v-btn,
    .arrow {
      margin-top: 0.5rem;
    }
    .arrows {
      display: inline-block;
      padding-top: 5px;
    }
  }
  @media ($wx-sm-min) {
    padding-right: 50px; // space for close-btn on the right
  }
}

// Fieldset
.justification-row {
  border: none;

  .v-btn {
    margin-top: 0.3rem;

    &.btn-delete {
      min-width: 30px;
      max-width: 30px;
      padding: 0;
    }
  }

  // mobilePhone only
  @media ($wx-xs-max) {
    margin-top: 1rem;

    ::v-deep .select-reason,
    .input-justification,
    .input-comment {
      max-width: 98%;
    }

    .v-btn {
      &.btn-delete {
        margin-top: -0.5rem;
        margin-bottom: 1rem;
      }
    }
  }
  // mobile & desktop
  @media ($wx-sm-min) {
    display: flex;
    gap: 10px;
    align-content: center;
    margin-top: 0.25rem;

    .input-justification {
      flex-grow: 1;
      min-width: 120px;
      max-width: 150px;
    }
    .input-comment {
      flex-grow: 1;
    }
    ::v-deep .select-reason {
      max-width: 320px;
    }
  }
}

//  Justification Reason Dialog
.select-reason--items {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .state-icon {
    opacity: 0.5;
  }

  .inline-target-value {
    color: var(--color-text-subtle-theme);
    font-weight: 300;
    white-space: nowrap;

    .v-icon {
      margin-top: -0.2rem;
      padding-right: 0.2rem;
      opacity: 0.6;
    }
  }
}
