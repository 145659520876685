@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.hide-panel {
  display: none;
}

.dropdown-nav {
  flex-grow: 0;
  max-width: 20rem;

  @media ($wx-xl-min) {
    max-width: 22rem;
  }
}

/**
 * Manage the height distribution of the 3 main zone of the page
 */

.main-content-wrapper,
.dashboard-content {
  display: flex;
  flex-direction: column;
}

// Manage responsive height
.main-content-wrapper {
  min-height: 500px;

  @media ($wx-isNotMobile) {
    height: 100%;
  }

  .dashboard-content {
    height: 100%;

    .v-item-group {
      flex-grow: 1;
    }
  }
}

.v-application {
  &:not(.presenter) {
    .main-content-wrapper {
      @media ($wx-lg-min) {
        min-height: 600px;
      }
      @media ($wx-xl-min) {
        min-height: 700px;
      }
    }
  }
  /**
   * If presenter TV display mode, prevent vertical scrollbar.
   * Officially we only support viewport ration of 16:9,
   * but up to a squessed ratio of 199:55, everything displays OK.
   */
  &.presenter {
    .main-content-wrapper {
      @media ($wx-lg-min) {
        min-height: 540px;
      }
      @media ($wx-xl-min) {
        min-height: 650px;
      }
    }
  }
}

.v-window {
  display: flex;
  overflow: visible;

  ::v-deep .v-window__container {
    flex-grow: 1;
    margin: 0 calc(#{var(--grid-gutter)} * -1);
  }
  .v-window-item {
    position: relative;
    padding: calc((var(--grid-gutter) / 3) + 12px) var(--grid-gutter) var(--grid-gutter);

    .tab-inner {
      position: relative;
      height: 100%;
    }

    @media ($wx-md-min) {
      height: 100%;
      overflow: hidden;

      .tab-inner {
        min-height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &:not(.presenter) {
      @media ($wx-md-min) {
        .tab-inner {
          height: calc(100vh - 200px);
        }
      }

      @media ($wx-lg-min) {
        .tab-inner {
          min-height: 600px;
          height: calc(100vh - 210px);
        }
      }

      @media ($wx-xl-min) {
        .tab-inner {
          min-height: 700px;
          height: calc(100vh - 235px);
        }
      }
    }

    &.presenter {
      @media ($wx-md-min) {
        .tab-inner {
          height: calc(100vh - 250px);
        }
      }

      @media ($wx-lg-min) {
        .tab-inner {
          min-height: 540px;
          height: calc(100vh - 270px);
        }
      }

      @media ($wx-xl-min) {
        .tab-inner {
          min-height: 650px;
          height: calc(100vh - 310px);
        }
      }
    }
  }
}

/**
 * Dashbords's 3 rows of contents in each tabs
 * within `section.dashboard-content`
 */
.tile-container,
.panel-container {
  position: relative;
}

// 1st row
.tile-container {
  margin-bottom: var(--grid-gutter);

  @media ($wx-md-min) {
    height: calc(40% - var(--grid-gutter));
    justify-content: flex-start;
  }

  .tile-slider {
    display: flex;
    column-gap: var(--grid-gutter);
    flex-flow: row wrap;
    height: 100%;

    @media (max-width: $wx-max-width-phone-vertical) {
      justify-content: center;
    }
    @media (min-width: $wx-min-width-phone-vertical) {
      justify-content: flex-start;
    }
    @media ($wx-md-min) {
      column-gap: 0;
      flex-flow: row nowrap;
      transform: translateX(calc((100% - var(--grid-gutter) / 2 * 4) / 5 * -1 - (var(--grid-gutter) / 2)));
      transition: var(--smooth-transition);

      &::after {
        content: none;
      }

      // .tile-slider > .tile
      ::v-deep .tile.v-card {
        &:first-child,
        &:nth-child(6) {
          transition: var(--smooth-transition);
        }
        &:first-child {
          transform: translateX(calc(var(--grid-gutter) * -1.5));
        }
      }
      // .tile-slider.isDataSourceAlerts
      &.isDataSourceAlerts {
        transform: translateX(0);

        // .tile-slider.isDataSourceAlerts > .tile
        .tile {
          &:first-child {
            transform: translateX(0);
          }
          &:nth-child(6) {
            transform: translateX(calc(var(--grid-gutter) * 1.5));
          }
        }
      }
    }
    @media ($wx-lg-min) {
      // .tile-slider
      margin-bottom: calc(var(--grid-gutter) * 1.5);
    }
  }
}

.panel-container {
  // 2nd row panels
  &--production-timeline,
  &--giveaway {
    height: auto;
    margin-bottom: var(--grid-gutter);

    @media ($wx-md-min) {
      margin-bottom: 0;
      position: absolute;
      z-index: 1;
      height: 60%;
      width: 100%;
      left: 0;
      bottom: 0;
      transition: var(--big-transition); // big elements requires more time to move to prevent dizziness

      &.isUp {
        transform: translateY(-66.666666%);
      }
    }

    // Both panel-timeline-oee and panel-giveaway
    ::v-deep .wx-panel.v-card {
      transition: var(--smooth-transition);
      height: 100%;

      @at-root .panel-container--production-timeline.isUp #{&} {
        box-shadow: var(--box-shadow-high-elevation);
      }
    }
  }
  // 3rd row panel
  &--justification {
    ::v-deep .panel-downtime-justification {
      height: auto;
    }

    @media ($wx-md-min) {
      height: calc(40% - var(--grid-gutter));
      ::v-deep .panel-downtime-justification {
        height: calc(100% - 1px);
      }
    }
  }
}

// Conditional chip
.main-title {
  .no-data-warning {
    max-height: 1.3em;
    color: black;
    font-weight: 400; // Normal
  }
  ::v-deep .v-input--switch {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-label {
      font-size: var(--font-size-h3) !important;
    }
  }

  &--toggle-buttons-active {
    // mobilePhone
    @media ($wx-xs-max) {
      .main-title {
        &__text {
          display: block;
          margin-bottom: 0.25rem;
        }
        &__buttons.v-item-group {
          width: 100%;
          .v-btn {
            width: 50%;
          }
        }
      }
    }
    // mobileTablet & desktop
    @media ($wx-sm-min) {
      display: flex;
      align-items: center;

      .main-title {
        &__buttons {
          margin-top: 0.05em;
        }
      }
    }
  }
}

// WxDatePickerByTimeframe
::v-deep .main-title-tab--giveaway .datepicker-col--first-column {
  // Fixing responsive issue in FR on mini tablets
  @media (min-width: $wx-breakpoint-md) and (max-width: $wx-breakpoint-lg) {
    flex-direction: column;
    .timeframe--aside {
      padding-left: 0.6rem;
      // .timeframe--aside__shift-name
      &__shift-name,
      .wx-contextualized-help__activator.text {
        position: absolute;
      }
      // .timeframe--aside__datepicker-activator
      &__datepicker-activator {
        margin-left: 14.25rem !important;
      }
    }
  }
}
::v-deep .datepicker-col--optional-column-container {
  .measures-export-dialog__activator-btn,
  .sampling-btn {
    @media ($wx-isMobile) {
      flex-grow: 1;

      @media ($wx-xs-max) {
        width: 70%;
        min-width: 15rem; // matching FR button text length
      }
    }
    @media ($wx-isNotMobile) {
      flex-grow: 0;
    }
    @media ($wx-lg-min) {
      min-width: 8.5rem;
    }
    @media ($wx-xl-min) {
      min-width: 14rem;
    }
  }
}
