@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-dialog {
  min-width: 256px;
  background: var(--color-flat-panel-modal-theme);
  margin: 0;

  @media ($wx-sm-min) {
    max-width: 540px;
  }

  @media ($wx-md-min) {
    max-width: 720px;
  }
}

.early-shift-finish {
  opacity: 0.3;
}

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}

.content {
  position: relative;
  width: auto;
  max-width: 100%;
  padding: var(--dialog-padding);
}

.v-list {
  @media ($wx-sm-min) {
    display: flex;
    flex-flow: row wrap;
    padding: 20px 0;
  }

  @media ($wx-md-min) {
    padding: 24px 0;
  }

  .v-list-item {
    padding: 0;
    min-height: 28px;

    @media ($wx-sm-min) {
      width: 50%;
      flex: 0 0 auto;
    }

    @media ($wx-md-min) {
      min-height: 32px;
    }

    @media ($wx-lg-min) {
      min-height: 38px;
    }

    @media ($wx-xl-min) {
      min-height: 42px;
    }

    .v-list-item__icon {
      align-self: center;
      margin: 0 12px 0 0;
    }

    .v-list-item__content {
      padding: 0;
    }
  }
}

.no-icon {
  position: relative;
  width: 16px;
  height: 16px;

  @media ($wx-md-min) {
    width: 24px;
    height: 24px;
  }

  &.colored-dot {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: currentColor;

      @media ($wx-md-min) {
        width: 16px;
        height: 16px;
      }
    }

    .theme--dark & {
      &.no-data:before {
        border: 1px solid white;
      }
    }
  }

  &.oeeObjective:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    border-top: 2px dashed var(--color-error);
  }

  &.upcomingTime {
    background-image: var(--repeating-oblique-small-pattern);
  }
}

.actions {
  text-align: center;
}
