<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card class="pa-5">
      <v-card-title>
        <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
        {{ $t("dashboard.productSampling.invalidSamplingProductDialog.title") }}
      </v-card-title>
      <v-card-text class="pl-14">
        {{
          $t("dashboard.productSampling.invalidSamplingProductDialog.info", {
            productName: this.productName,
          })
        }}
      </v-card-text>
      <v-card-text class="pl-14 visible-bullets">
        <ul>
          <li>
            {{ $t("dashboard.productSampling.invalidSamplingProductDialog.item1") }}
          </li>
          <li>
            {{ $t("dashboard.productSampling.invalidSamplingProductDialog.item2") }}
          </li>
          <li>
            {{ $t("dashboard.productSampling.invalidSamplingProductDialog.item3") }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <wx-btn-standard color="secondary" @click="cancel">{{
          $t("dashboard.productSampling.invalidSamplingProductDialog.cancel")
        }}</wx-btn-standard>
        <wx-btn-standard color="primary" @click="setupProduct">{{
          $t("dashboard.productSampling.invalidSamplingProductDialog.setup")
        }}</wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
export default {
  components: { WxBtnStandard },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productName: {
      type: String,
      default: "",
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    setupProduct() {
      this.$emit("setupProduct");
    },
  },
};
</script>

<style scoped>
.visible-bullets ul {
  list-style-type: circle !important;
  padding-left: 1.5em;
  margin: 0;
}
</style>
