@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// Styles
.activator-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    top: var(--box-padding-dashboard);
    right: var(--box-padding-dashboard);
    min-width: 0 !important;
    padding: 0 !important;
  }
}
